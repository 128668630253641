import { createRouter, createWebHistory } from "vue-router";
// Layout -> GroupWrapper -> (UserWrapper -> Authenticated Area, NonUserWrapper -> Non User , Other Pages)
import Blank from "@/components/Blank.vue";
import NotFound from "@/components/NotFound.vue";
import Layout from "@/components/Layout.vue";
import GroupWrapper from "@/components/GroupWrapper.vue";
import UserWrapper from "@/components/UserWrapper.vue";
import NonUserWrapper from "@/components/NonUserWrapper.vue";
import Home from "@/views/Home.vue";
import Top from "@/views/Top.vue";
import Login from "@/views/Login.vue";
import PhotoList from "@/views/PhotoList.vue";
import PhotosEdit from "@/views/PhotosEdit.vue";
import Photo from "@/views/Photo.vue";
import TagList from "@/views/TagList.vue";
import UserList from "@/views/UserList.vue";
const routeChildren = [
    {
        path: ":groupId",
        component: GroupWrapper,
        children: [
            {
                path: "",
                component: Home,
            },
            {
                path: "",
                component: UserWrapper,
                children: [
                    {
                        path: "photos",
                        component: PhotoList,
                    },
                    {
                        path: "photos/edit",
                        component: PhotosEdit,
                    },
                    {
                        path: "photos/user/:userId/photo/:photoId",
                        component: Photo,
                    },
                    {
                        path: "tags",
                        component: TagList,
                    },
                    {
                        path: "users",
                        component: UserList,
                    },
                ],
            },
            {
                path: "",
                component: NonUserWrapper,
                children: [
                    {
                        path: "login",
                        component: Login,
                    },
                ],
            },
            {
                path: "/:page(.*)",
                name: "NotFoundPage",
                component: NotFound,
            },
        ],
    },
    {
        path: "",
        component: Top,
    },
];
const routes = [
    {
        path: "/",
        component: Layout,
        children: [
            {
                path: "/:lang(ja|en)",
                component: Blank,
                children: routeChildren,
            },
            {
                path: "",
                component: Blank,
                children: routeChildren,
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
