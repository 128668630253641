import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_NotFound = _resolveComponent("NotFound");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.hasGroup === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : (_ctx.hasGroup)
                ? (_openBlock(), _createBlock(_component_router_view, {
                    key: 1,
                    groupId: _ctx.groupId,
                    group: _ctx.group
                }, null, 8, ["groupId", "group"]))
                : (_openBlock(), _createBlock(_component_NotFound, { key: 2 }))
    ]));
}
