import { computed } from "vue";
import { useStore } from "vuex";
export const useUserData = () => {
    const store = useStore();
    const user = computed(() => store.state.user);
    const isSignedIn = computed(() => store.getters.isSignedIn);
    const isLoading = computed(() => store.state.user === undefined);
    return {
        isLoading,
        user,
        isSignedIn,
    };
};
export const sliceForFirebaseWhere = (target, size = 10) => {
    const result = [];
    for (let i = 0; i < target.length; i += size) {
        result.push(target.slice(i, i + size));
    }
    return result;
};
const regex = /[^a-zA-Z0-9]/g;
export const removeSymbols = (target) => target.replace(regex, "");
