import FirebaseModel from "./firebasemodel";
export default class User extends FirebaseModel {
    constructor(_model) {
        super(_model);
    }
    getRealName() {
        return this.data.realName || "Unknown";
    }
    getPhoneNumber() {
        return this.data.phone || "Unknown";
    }
}
