import { defineComponent, ref, computed, watch } from "vue";
import { db } from "@/utils/firebase";
import { onSnapshot, doc, collection, updateDoc, setDoc, deleteDoc, serverTimestamp, } from "firebase/firestore";
import { useRoute } from "vue-router";
import LinkButton from "@/components/LinkButton.vue";
import { usePhotoList, photoFilters } from "@/utils/PhotoUtil";
import { removeSymbols } from "@/utils/utils";
import Photo from "@/models/photo";
import PhotoTag from "@/models/photoTag";
export default defineComponent({
    components: {
        LinkButton,
    },
    props: {
        groupId: {
            type: String,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const photoStatus = ref("1");
        const route = useRoute();
        const photo = ref(null);
        const photoTags = ref([]);
        const photoTagObj = ref({});
        const visibleTags = computed(() => props.tags.filter((tag) => tag.visible()));
        const { likesIndexedByPhotoId, users } = usePhotoList(props.group.id);
        const photoId = computed(() => route.params.photoId);
        const userId = computed(() => route.params.userId);
        onSnapshot(doc(db, `users/${removeSymbols(userId.value)}/photos/${removeSymbols(photoId.value)}`), (photoDoc) => {
            photo.value = new Photo(photoDoc);
            photoStatus.value = photo.value.data.status;
        });
        onSnapshot(collection(db, `users/${removeSymbols(userId.value)}/photos/${removeSymbols(photoId.value)}/photoTags`), (tagSnapshot) => {
            photoTags.value = tagSnapshot.docs.map((doc) => {
                const tag = new PhotoTag(doc);
                photoTagObj.value[tag.id] = tag;
                return tag;
            });
        });
        watch(photoStatus, (v) => {
            updateDoc(doc(db, `users/${removeSymbols(userId.value)}/photos/${removeSymbols(photoId.value)}`), {
                status: v,
            });
        });
        const updateTag = (id, e) => {
            const value = e.target.checked;
            const data = {
                uid: userId.value,
                photoId: photoId.value,
                tagId: id,
                groupId: props.group.id,
                updatedAt: serverTimestamp(),
            };
            const path = `users/${removeSymbols(userId.value)}/photos/${removeSymbols(photoId.value)}/photoTags/${removeSymbols(id)}`;
            if (value) {
                setDoc(doc(db, path), data, { merge: true });
            }
            else {
                deleteDoc(doc(db, path));
            }
        };
        return {
            photo,
            photoTags,
            photoTagObj,
            visibleTags,
            likesIndexedByPhotoId,
            users,
            updateTag,
            photoFilters,
            photoStatus,
        };
    },
});
