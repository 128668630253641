import { computed, defineComponent, ref } from "vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/utils/firebase";
export default defineComponent({
    setup() {
        const email = ref("");
        const password = ref("");
        const isLoading = ref(false);
        const isConfirm = ref(false);
        const errorEmail = ref("");
        const errorPassword = ref("");
        const errors = computed(() => {
            const ret = [];
            const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
            if (!regex.test(email.value)) {
                console.log(email.value);
                ret.push("メールアドレスを入力してください。");
            }
            return ret;
        });
        const hasError = computed(() => {
            return errors.value.length > 0;
        });
        const errorsPassword = computed(() => {
            const ret = [];
            if (password.value.length == 0) {
                console.log(password.value);
                ret.push("パスワードを入力してください。");
            }
            return ret;
        });
        const hasErrorPassword = computed(() => {
            return errors.value.length > 0;
        });
        const focus = () => {
            errorEmail.value = "";
        };
        const handleSubmit = async () => {
            console.log("handleSubmit"); // TODO: 一度しかおしてないのに2回呼ばれる?
            try {
                isLoading.value = true;
                const confirmationResult = await signInWithEmailAndPassword(auth, email.value, password.value);
                console.log("confirmationResult:", confirmationResult);
                isConfirm.value = true;
            }
            catch (error) {
                console.log("error", error.code);
                if (error.code == "auth/user-not-found") {
                    errorEmail.value = "メールアドレスが登録されていません。";
                }
                else if (error.code == "auth/wrong-password") {
                    errorPassword.value = "パスワードが違います。";
                }
            }
            finally {
                isLoading.value = false;
            }
        };
        return {
            handleSubmit,
            email,
            password,
            errors,
            hasError,
            errorsPassword,
            hasErrorPassword,
            isLoading,
            isConfirm,
            errorEmail,
            errorPassword,
            focus,
        };
    },
});
