import { defineComponent, ref } from "vue";
import { db } from "@/utils/firebase";
import { getDocs, query, collection, where } from "firebase/firestore";
import Group from "@/models/group";
import NotFound from "@/components/NotFound.vue";
import { useRoute } from "vue-router";
export default defineComponent({
    components: {
        NotFound,
    },
    setup() {
        console.log("groupWrapper");
        const route = useRoute();
        const groupId = route.params.groupId;
        const hasGroup = ref(null);
        const group = ref(null);
        getDocs(query(collection(db, "groups"), where("name", "==", groupId))).then((groupSnapshot) => {
            hasGroup.value = groupSnapshot.docs.length === 1;
            if (hasGroup.value) {
                group.value = new Group(groupSnapshot.docs[0]);
            }
        });
        return {
            groupId,
            group,
            hasGroup,
        };
    },
});
