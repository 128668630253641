import { defineComponent, watchEffect } from "vue";
import { useUserData, removeSymbols } from "@/utils/utils";
import router from "@/router";
export default defineComponent({
    props: {
        groupId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { user } = useUserData();
        console.log("home");
        watchEffect(() => {
            console.log(props);
            if (user.value) {
                console.log("redirect");
                router.push(`/ja/${removeSymbols(props.groupId)}/photos`);
            }
            if (user.value === null) {
                console.log("redirect");
                router.push(`/ja/${removeSymbols(props.groupId)}/login`);
            }
        });
        return {};
    },
});
