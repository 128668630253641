import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_router_view, {
                    tags: _ctx.tags,
                    group: _ctx.group,
                    groupId: _ctx.groupId
                }, null, 8, ["tags", "group", "groupId"])
            ]))
    ]));
}
