import FirebaseModel from "./firebasemodel";
export default class Tag extends FirebaseModel {
    tagId;
    color;
    constructor(_model) {
        super(_model);
        this.tagId = this.data.tagId;
        this.color = Tag.convertToColorHex(this.id);
    }
    static convertToColorHex(str) {
        if (!str)
            return "#000000";
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            colour += ("00" + value.toString(16)).slice(-2);
        }
        return colour;
    }
    visible() {
        return !this.data.hidden && !this.data.deleted;
    }
    isHidden() {
        return this.data.hidden;
    }
    deleted() {
        return this.data.deleted;
    }
}
