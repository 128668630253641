import { defineComponent, ref, computed, watch } from "vue";
import { auth } from "@/utils/firebase";
import { signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { cdate } from "cdate";
import LinkButton from "@/components/LinkButton.vue";
import { usePhotoList, useTagList, parseQuery, photoFilters, filterPhotos, } from "@/utils/PhotoUtil";
import { removeSymbols } from "@/utils/utils";
export default defineComponent({
    components: {
        LinkButton,
        VueDatePicker,
    },
    props: {
        tags: {
            type: (Array),
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const visibleTags = computed(() => props.tags.filter((tag) => tag.visible()));
        const { photos, photoCounter, likesIndexedByPhotoId, users } = usePhotoList(props.group.id);
        const logout = async () => {
            try {
                await signOut(auth);
                router.push(`/ja/${removeSymbols(props.groupId)}/login`);
            }
            catch (error) {
                console.log("error", error);
            }
        };
        const { searchWords: _searchWords, searchCreatedDate: _searchCreatedDate, tagFilters: _tagFilters, statusFilter: _statusFilter, } = parseQuery();
        const statusFilter = ref(_statusFilter);
        const updateStatusFilter = (filterKey) => {
            statusFilter.value = filterKey;
        };
        const initTagFilters = {};
        _tagFilters.map((tagId) => (initTagFilters[tagId] = true));
        const tagFilters = ref(initTagFilters);
        const updateTags = (id, e) => {
            const checked = e.target.checked;
            tagFilters.value[id] = checked;
        };
        const selectedTags = computed(() => visibleTags.value.filter((tag) => tagFilters.value[tag.id]));
        const { getTagsIndexedByPhotoId, photoTagCounter } = useTagList(props.group.id);
        const filteredPhotos = computed(() => {
            return filterPhotos(photos.value, getTagsIndexedByPhotoId(), statusFilter.value, selectedTags.value.map((tag) => tag.id), searchWords.value, users.value, searchCreatedDate.value);
        });
        const searchText = ref(_searchWords.join(" "));
        const searchWords = computed(() => searchText.value.split(/\u3000|\s/g).filter((word) => word !== ""));
        const searchCreatedDate = ref(_searchCreatedDate);
        const convertDateToStringForTemplateAndQuery = (date) => {
            return cdate(date).locale("ja").format("YYYY/MM/DD");
        };
        const dateFormat = (dates) => {
            return dates
                .map((date) => convertDateToStringForTemplateAndQuery(date))
                .join(" - ");
        };
        const query = computed(() => {
            const _query = {};
            if (searchText.value)
                _query["searchText"] = searchText.value;
            if (searchCreatedDate.value && searchCreatedDate.value.length === 2) {
                Object.entries({
                    fromDate: searchCreatedDate.value[0],
                    toDate: searchCreatedDate.value[1],
                }).map(([key, date]) => {
                    _query[key] = convertDateToStringForTemplateAndQuery(date);
                });
            }
            if (Object.values(tagFilters.value).some((checked) => checked)) {
                _query["tagFilters"] = Object.keys(tagFilters.value).filter((tagId) => tagFilters.value[tagId]);
            }
            if (statusFilter.value)
                _query["statusFilter"] = statusFilter.value;
            return _query;
        });
        watch(() => query.value, (q) => router.replace({ query: q }));
        return {
            filteredPhotos,
            getTagsIndexedByPhotoId,
            logout,
            statusFilter,
            updateStatusFilter,
            updateTags,
            tagFilters,
            visibleTags,
            photoTagCounter,
            photoFilters,
            photoCounter,
            likesIndexedByPhotoId,
            users,
            searchText,
            searchCreatedDate,
            dateFormat,
            query,
            removeSymbols,
        };
    },
});
