import FirebaseModel from "./firebasemodel";
import { cdate } from "cdate";
// copy from customer web.
// if you update this model, you should sync this file.
export default class Photo extends FirebaseModel {
    static Status = {
        before_validation: 1,
        validation_ok: 200,
        published: 300,
        notPublished: 400,
        deleted: 500, // 削除済み
    };
    static StatusForFilter = {
        1: "latestphotos",
        // 200: "validation_ok", // ?
        300: "onthelist",
        400: "offthelist",
        500: "trash", // ゴミ箱
    };
    photoUrl;
    smallPhotoUrl;
    status;
    imageListCss;
    constructor(_model) {
        super(_model);
        this.photoUrl =
            this.data.resized && this.data.resized["1200"]
                ? this.data.resized["1200"]
                : this.data.photoURL;
        this.smallPhotoUrl =
            this.data.resized && this.data.resized["600"]
                ? this.data.resized["600"]
                : this.data.photoURL;
        this.status = this.data.status || Photo.Status.before_validation;
        this.imageListCss = Photo.getImageListCss(this.status);
    }
    getStatusName() {
        return Photo.StatusForFilter[this.status];
    }
    static getImageListCss(status) {
        if (status === Photo.Status.before_validation) {
            return {
                border: "",
                //border: "border-4 border-blue-500",
                image: "",
                labelClass: "bg-orange-400",
                labelMessage: "新",
            };
        }
        else if (status === Photo.Status.published) {
            return {
                border: "",
                image: "",
                labelClass: "bg-sky-400",
                labelMessage: "公",
            };
        }
        else if (status === Photo.Status.notPublished) {
            return {
                // border: "border-4 border-yellow-300 bg-black",
                border: "",
                image: "opacity-70",
                labelClass: "bg-yellow-400",
                labelMessage: "隠",
            };
        }
        else if (status === Photo.Status.deleted) {
            return {
                border: "",
                // border: "border-4 border-black bg-black",
                image: "opacity-30",
                labelClass: "bg-black text-white",
                labelMessage: "除",
            };
        }
        return {
            border: "",
            image: "",
            labelClass: "",
            labelMessage: "",
        };
    }
    formatCreatedAt(format = "YYYY/MM/DD") {
        return cdate(this.data.createdAt.toDate()).locale("ja").format(format);
    }
    static convertComparableDate(date) {
        return Number(cdate(date).locale("ja").format("YYYYMMDD"));
    }
    isInDateRangeOfCreatedAt(dates) {
        if (!dates || dates.length === 0)
            return true;
        const createDate = Photo.convertComparableDate(this.data.createdAt.toDate());
        if (dates.length === 1) {
            return createDate >= Photo.convertComparableDate(dates[0]);
        }
        else {
            return (createDate >= Photo.convertComparableDate(dates[0]) &&
                createDate <= Photo.convertComparableDate(dates[1]));
        }
    }
}
