import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
    setup() {
        console.log("app");
        const store = useStore();
        const user = computed(() => store.state.user);
        const group = computed(() => store.state.group);
        const isSignedIn = computed(() => store.getters.isSignedIn);
        console.log("★★★★★★isSigneIn: ", store.getters.isSignedIn);
        return {
            user,
            group,
            isSignedIn,
        };
    },
});
