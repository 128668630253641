import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { auth } from "@/utils/firebase";
import { useI18nParam } from "@/i18n/utils";
export default defineComponent({
    name: "AppLayout",
    components: {},
    setup() {
        console.log("layout");
        const store = useStore();
        const user = reactive({ user: null });
        useI18nParam();
        onMounted(() => {
            auth.onAuthStateChanged((fbuser) => {
                if (fbuser) {
                    console.log("authStateChanged:");
                    user.user = fbuser;
                    store.commit("setUser", fbuser);
                }
                else {
                    store.commit("setUser", null);
                }
            });
        });
        return {
            user,
        };
    },
});
