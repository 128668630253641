import { defineComponent, watchEffect } from "vue";
import router from "@/router";
import { useUserData } from "@/utils/utils";
export default defineComponent({
    props: {
        groupId: {
            type: String,
            required: true,
        },
    },
    setup() {
        const { isSignedIn, isLoading } = useUserData();
        watchEffect(() => {
            console.log("■■■■■■■hoge■■■■■■■");
            console.log(isLoading.value, isSignedIn.value);
            if (!isLoading.value && isSignedIn.value) {
                router.push(`/ja/photos`);
            }
        });
        return {};
    },
});
