import { defineComponent, computed, ref } from "vue";
import { doc, serverTimestamp, writeBatch, } from "firebase/firestore";
import LinkButton from "@/components/LinkButton.vue";
import { db } from "@/utils/firebase";
import { usePhotoList, useTagList, parseQuery, photoFilters as _photoFilters, filterPhotos, } from "@/utils/PhotoUtil";
import { removeSymbols } from "@/utils/utils";
import Photo from "@/models/photo";
import Tag from "@/models/tag";
export default defineComponent({
    components: {
        LinkButton,
    },
    props: {
        tags: {
            type: (Array),
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const selectedPhotos = ref({});
        const { photos, users, likesIndexedByPhotoId } = usePhotoList(props.group.id);
        const { searchWords, searchCreatedDate, tagFilters, statusFilter } = parseQuery();
        const filteredPhotos = computed(() => {
            return filterPhotos(photos.value, getTagsIndexedByPhotoId(props.tags), statusFilter, tagFilters, searchWords, users.value, searchCreatedDate);
        });
        const filteredPhotosIndexedByPhotoId = computed(() => {
            const _filteredPhotosIndexedByPhotoId = {};
            filteredPhotos.value.map((photo) => (_filteredPhotosIndexedByPhotoId[photo.id] = photo));
            return _filteredPhotosIndexedByPhotoId;
        });
        const { getTagsIndexedByPhotoId, tags } = useTagList(props.group.id);
        const visibleTags = computed(() => props.tags.filter((tag) => tag.visible()));
        const photoFilters = _photoFilters.filter((filter) => filter.key !== "allphotos");
        const selectPhoto = (photo) => {
            if (selectedPhotos.value[photo.id]) {
                delete selectedPhotos.value[photo.id];
                Object.keys(tagsRequiredToAdd.value).map((tagId) => {
                    Object.keys(tagsRequiredToAdd.value[tagId])
                        .filter((photoId) => photoId === photo.id)
                        .map((photoId) => {
                        delete tagsRequiredToAdd.value[tagId][photoId];
                    });
                });
                Object.keys(tagsRequiredToDelete.value).map((tagId) => {
                    Object.keys(tagsRequiredToDelete.value[tagId])
                        .filter((photoId) => photoId === photo.id)
                        .map((photoId) => {
                        delete tagsRequiredToDelete.value[tagId][photoId];
                    });
                });
            }
            else {
                selectedPhotos.value[photo.id] = photo;
            }
        };
        const tagIdsIndexedByPhotoId = computed(() => {
            const _tagIdsIndexedByPhotoId = {};
            const tagsIndexedByPhotoId = getTagsIndexedByPhotoId(props.tags);
            Object.keys(tagsIndexedByPhotoId).map((photoId) => {
                tagsIndexedByPhotoId[photoId].map((tag) => {
                    if (!_tagIdsIndexedByPhotoId[photoId])
                        _tagIdsIndexedByPhotoId[photoId] = {};
                    _tagIdsIndexedByPhotoId[photoId][tag.id] = true;
                });
            });
            Object.keys(tagsRequiredToAdd.value).map((tagId) => {
                Object.keys(tagsRequiredToAdd.value[tagId]).map((photoId) => {
                    if (!_tagIdsIndexedByPhotoId[photoId])
                        _tagIdsIndexedByPhotoId[photoId] = {};
                    _tagIdsIndexedByPhotoId[photoId][tagId] = true;
                });
            });
            Object.keys(tagsRequiredToDelete.value).map((tagId) => {
                Object.keys(tagsRequiredToDelete.value[tagId]).map((photoId) => {
                    if (_tagIdsIndexedByPhotoId[photoId] &&
                        _tagIdsIndexedByPhotoId[photoId][tagId]) {
                        delete _tagIdsIndexedByPhotoId[photoId][tagId];
                    }
                });
            });
            return _tagIdsIndexedByPhotoId;
        });
        const selectedPhotoIdsIndexedByTagId = computed(() => {
            const _selectedPhotoIdsIndexedByTagId = {};
            Object.keys(selectedPhotos.value).map((photoId) => {
                if (!tagIdsIndexedByPhotoId.value[photoId])
                    return;
                Object.keys(tagIdsIndexedByPhotoId.value[photoId]).map((tagId) => {
                    if (!_selectedPhotoIdsIndexedByTagId[tagId])
                        _selectedPhotoIdsIndexedByTagId[tagId] = {};
                    _selectedPhotoIdsIndexedByTagId[tagId][photoId] = true;
                });
            });
            return _selectedPhotoIdsIndexedByTagId;
        });
        const allPhotoIdsIndexedByTagId = computed(() => {
            const tagsIndexedByTagId = {};
            const _allPhotoIdsIndexedByTagId = {};
            props.tags.map((tag) => {
                tagsIndexedByTagId[tag.id] = tag;
                _allPhotoIdsIndexedByTagId[tag.id] = {};
            });
            Object.entries(tags.value).map(([tagId, _tags]) => {
                if (tagsIndexedByTagId[tagId] && !tagsIndexedByTagId[tagId].visible())
                    return;
                _tags.map((tag) => {
                    if (!filteredPhotosIndexedByPhotoId.value[tag.data.photoId])
                        return;
                    _allPhotoIdsIndexedByTagId[tagId][tag.data.photoId] = true;
                });
                Object.keys(selectedPhotos.value).map((photoId) => {
                    _allPhotoIdsIndexedByTagId[tagId][photoId] = true;
                });
            });
            Object.values(tagsRequiredToAdd.value).map((newTagsIndexedByPhotoId) => {
                Object.values(newTagsIndexedByPhotoId).map((newTag) => (_allPhotoIdsIndexedByTagId[newTag.tagId][newTag.photoId] = true));
            });
            Object.values(tagsRequiredToDelete.value).map((deletingTagsIndexedByPhotoId) => {
                Object.values(deletingTagsIndexedByPhotoId).map((deletingTag) => delete _allPhotoIdsIndexedByTagId[deletingTag.tagId][deletingTag.photoId]);
            });
            return _allPhotoIdsIndexedByTagId;
        });
        const tagsRequiredToAdd = ref({});
        const tagsRequiredToDelete = ref({});
        const updateTags = (tagId) => {
            if (!selectedPhotoIdsIndexedByTagId.value[tagId] ||
                Object.keys(selectedPhotoIdsIndexedByTagId.value[tagId]).length === 0) {
                Object.values(selectedPhotos.value).map((photo) => {
                    if (!selectedPhotoIdsIndexedByTagId.value[tagId] ||
                        !selectedPhotoIdsIndexedByTagId.value[tagId][photo.id]) {
                        if (!tagsRequiredToAdd.value[tagId])
                            tagsRequiredToAdd.value[tagId] = {};
                        tagsRequiredToAdd.value[tagId][photo.id] = {
                            uid: photo.data.uid,
                            photoId: photo.id,
                            tagId: tagId,
                            groupId: photo.data.groupId,
                            updatedAt: serverTimestamp(),
                        };
                        if (tagsRequiredToDelete.value[tagId] &&
                            tagsRequiredToDelete.value[tagId][photo.id]) {
                            delete tagsRequiredToDelete.value[tagId][photo.id];
                        }
                    }
                });
            }
            else {
                Object.values(selectedPhotos.value).map((photo) => {
                    if (selectedPhotoIdsIndexedByTagId.value[tagId] &&
                        selectedPhotoIdsIndexedByTagId.value[tagId][photo.id]) {
                        if (tagsRequiredToAdd.value[tagId] &&
                            tagsRequiredToAdd.value[tagId][photo.id]) {
                            delete tagsRequiredToAdd.value[tagId][photo.id];
                        }
                        if (!tagsRequiredToDelete.value[tagId])
                            tagsRequiredToDelete.value[tagId] = {};
                        tagsRequiredToDelete.value[tagId][photo.id] = {
                            uid: photo.data.uid,
                            photoId: photo.id,
                            tagId: tagId,
                        };
                    }
                });
            }
        };
        const selectedPhotoFilter = ref(undefined);
        const CurrentImageListCss = computed(() => {
            if (selectedPhotoFilter.value) {
                return Photo.getImageListCss(selectedPhotoFilter.value);
            }
            else {
                return {
                    border: "",
                    image: "",
                    labelClass: "",
                    labelMessage: "",
                };
            }
        });
        const disabledForSubmitButton = ref(false);
        const submit = async () => {
            disabledForSubmitButton.value = true;
            const batch = writeBatch(db);
            Object.keys(tagsRequiredToAdd.value).map((tagId) => {
                Object.values(tagsRequiredToAdd.value[tagId]).map((newTag) => {
                    batch.set(doc(db, `/users/${removeSymbols(newTag.uid)}/photos/${removeSymbols(newTag.photoId)}/photoTags/${removeSymbols(newTag.tagId)}`), newTag);
                });
            });
            Object.keys(tagsRequiredToDelete.value).map((tagId) => {
                Object.values(tagsRequiredToDelete.value[tagId]).map((tagInfoToDelete) => {
                    batch.delete(doc(db, `/users/${removeSymbols(tagInfoToDelete.uid)}/photos/${removeSymbols(tagInfoToDelete.photoId)}/photoTags/${removeSymbols(tagInfoToDelete.tagId)}`));
                });
            });
            if (selectedPhotoFilter.value) {
                Object.values(selectedPhotos.value).map((photo) => {
                    batch.update(doc(db, `users/${removeSymbols(photo.data.uid)}/photos/${removeSymbols(photo.id)}`), {
                        status: selectedPhotoFilter.value,
                    });
                });
            }
            await batch
                .commit()
                .then(() => {
                tagsRequiredToAdd.value = {};
                tagsRequiredToDelete.value = {};
                selectedPhotoFilter.value = undefined;
                selectedPhotos.value = {};
            })
                .catch((e) => {
                // todo: batch limit 500
                console.log(e);
            })
                .finally(() => {
                disabledForSubmitButton.value = false;
            });
        };
        const selectAllPhotos = () => {
            filteredPhotos.value.map((photo) => (selectedPhotos.value[photo.id] = photo));
        };
        const clearSelectedPhotos = () => {
            tagsRequiredToAdd.value = {};
            tagsRequiredToDelete.value = {};
            selectedPhotoFilter.value = undefined;
            selectedPhotos.value = {};
        };
        return {
            Tag,
            selectedPhotos,
            visibleTags,
            photoFilters,
            filteredPhotos,
            users,
            likesIndexedByPhotoId,
            selectPhoto,
            tagIdsIndexedByPhotoId,
            selectedPhotoIdsIndexedByTagId,
            allPhotoIdsIndexedByTagId,
            updateTags,
            selectedPhotoFilter,
            CurrentImageListCss,
            disabledForSubmitButton,
            submit,
            selectAllPhotos,
            clearSelectedPhotos,
        };
    },
});
