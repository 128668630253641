import { defineComponent, ref, watchEffect } from "vue";
import { db } from "@/utils/firebase";
import { onSnapshot, collection } from "firebase/firestore";
import router from "@/router";
import { useUserData, removeSymbols } from "@/utils/utils";
import Tag from "@/models/tag";
export default defineComponent({
    props: {
        groupId: {
            type: String,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { isSignedIn, isLoading } = useUserData();
        const tags = ref([]);
        const path = `/groups/${removeSymbols(props.group.id)}/tags`;
        onSnapshot(collection(db, path), (snapShot) => {
            tags.value = snapShot.docs.map((doc) => {
                return new Tag(doc);
            });
        });
        watchEffect(() => {
            console.log("■■■■■■■hoge■■■■■■■");
            console.log(isLoading.value, isSignedIn.value);
            if (!isLoading.value && !isSignedIn.value) {
                router.push(`/ja/login`);
            }
        });
        return {
            isLoading,
            tags,
        };
    },
});
