import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = { class: "m-2" };
const _hoisted_3 = { class: "text-base font-bold text-white" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_router_link, {
            to: { path: _ctx.localizedUrl(`/${_ctx.groupId}/${_ctx.path}`), query: _ctx.query || {} },
            custom: ""
        }, {
            default: _withCtx(({ navigate }) => [
                _createElementVNode("button", {
                    onClick: navigate,
                    role: "link",
                    class: "h-fit w-full items-center justify-center rounded-lg bg-green-600 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-20"
                }, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name), 1)
                    ])
                ], 8, _hoisted_1)
            ]),
            _: 1
        }, 8, ["to"])
    ]));
}
