import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { removeSymbols } from "@/utils/utils";
export default defineComponent({
    props: {
        path: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
            required: false,
        },
    },
    setup() {
        const route = useRoute();
        const groupId = removeSymbols(route.params.groupId);
        return {
            groupId,
        };
    },
});
