import { ref, computed, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { removeSymbols } from "@/utils/utils";
import User from "@/models/user";
import Like from "@/models/like";
import Photo from "@/models/photo";
import Tag from "@/models/tag";
import { db } from "@/utils/firebase";
import { query, onSnapshot, collectionGroup, where, orderBy, collection, } from "firebase/firestore";
export const usePhotoList = (groupId) => {
    const photos = ref([]);
    const likesIndexedByPhotoId = ref({});
    const detacher = onSnapshot(query(collectionGroup(db, "photos"), where("groupId", "==", groupId), orderBy("createdAt", "desc")), (photo) => {
        if (!photo.empty) {
            photos.value = photo.docs.map((photoDoc) => new Photo(photoDoc));
        }
    }, (error) => {
        console.log(error);
    });
    const detacherForLike = onSnapshot(query(collection(db, `groups/${removeSymbols(groupId)}/likes`)), (like) => {
        if (!like.empty) {
            like.docs.map((doc) => {
                const like = new Like(doc);
                likesIndexedByPhotoId.value[like.data.photoId] = like;
            });
        }
    }, (error) => {
        console.log(error);
    });
    const photoCounter = computed(() => {
        return photos.value.reduce((tmp, a) => {
            if (!tmp[a.data.status]) {
                tmp[a.data.status] = 0;
            }
            tmp[a.data.status]++;
            tmp["all"]++;
            return tmp;
        }, { all: 0 });
    });
    const users = ref({});
    const detacherForUser = onSnapshot(query(collection(db, "users"), where("groupId", "==", groupId), orderBy("createdAt", "desc")), (user) => {
        if (!user.empty) {
            user.docs.map((doc) => {
                users.value[doc.id] = new User(doc);
            });
        }
    }, (error) => {
        console.log(error);
    });
    onUnmounted(() => {
        detacher();
        detacherForLike();
        detacherForUser();
    });
    return {
        photos,
        photoCounter,
        likesIndexedByPhotoId,
        users,
    };
};
export const useTagList = (groupId) => {
    const tags = ref({});
    const photoTagCounter = ref({});
    const detacher = onSnapshot(query(collectionGroup(db, "photoTags"), where("groupId", "==", groupId)), (tagColl) => {
        if (!tagColl.empty) {
            const _tags = {};
            const counter = {};
            tagColl.docs.map((tagDoc) => {
                const tag = new Tag(tagDoc);
                if (!_tags[tag.data.tagId]) {
                    _tags[tag.data.tagId] = [];
                    counter[tag.data.tagId] = 0;
                }
                _tags[tag.data.tagId].push(tag);
                counter[tag.data.tagId]++;
            });
            tags.value = _tags;
            photoTagCounter.value = counter;
        }
    }, (error) => {
        console.log(error);
    });
    const getTagsIndexedByPhotoId = (_tags = []) => {
        const tagsIndexedByTagId = {};
        _tags.map((tag) => (tagsIndexedByTagId[tag.id] = tag));
        const _tagsIndexedByPhotoId = {};
        Object.values(tags.value).map((tags) => {
            tags.map((tag) => {
                if (tagsIndexedByTagId[tag.id] && !tagsIndexedByTagId[tag.id].visible())
                    return;
                if (!_tagsIndexedByPhotoId[tag.data.photoId])
                    _tagsIndexedByPhotoId[tag.data.photoId] = [];
                _tagsIndexedByPhotoId[tag.data.photoId].push(tag);
            });
        });
        return _tagsIndexedByPhotoId;
    };
    onUnmounted(() => {
        detacher();
    });
    return {
        tags,
        getTagsIndexedByPhotoId,
        photoTagCounter,
    };
};
const isInvalidDate = (date) => Number.isNaN(date.getTime());
export const parseQuery = () => {
    const route = useRoute();
    const searchWords = (route.query.searchText || "")
        .toString()
        .split(/\u3000|\s/g)
        .filter((word) => word !== "");
    const searchCreatedDate = [];
    if (route.query.fromDate && route.query.toDate) {
        const fromDate = new Date(route.query.fromDate.toString());
        const toDate = new Date(route.query.toDate.toString());
        if (!isInvalidDate(fromDate) && !isInvalidDate(toDate)) {
            searchCreatedDate.push(fromDate, toDate);
        }
    }
    const tagFilters = Array.from(Array.isArray(route.query.tagFilters)
        ? route.query.tagFilters
        : [route.query.tagFilters ?? ""])
        .map((tagId) => (tagId || "").toString())
        .filter((tagId) => tagId !== "");
    const statusFilter = route.query.statusFilter?.toString() || "";
    return {
        searchWords,
        searchCreatedDate,
        tagFilters,
        statusFilter,
    };
};
export const filterPhotos = (photos, tagsIndexedByPhotoId, statusFilter = "", tagFilters = [], searchWords = [], users = {}, searchCreatedDate = []) => {
    return (statusFilter === "" || statusFilter === "allphotos"
        ? photos
        : photos.filter((photo) => photo.getStatusName() === statusFilter))
        .filter((photo) => {
        if (tagFilters.length === 0)
            return true;
        if (!tagsIndexedByPhotoId[photo.id])
            return false;
        return tagFilters.every((tagId) => tagsIndexedByPhotoId[photo.id].some((tag) => tag.id === tagId));
    })
        .filter((photo) => {
        if (searchWords.length === 0)
            return true;
        return searchWords.every((word) => {
            if (!users[photo.data.uid])
                return false;
            if (word.startsWith("uid:")) {
                return users[photo.data.uid].id === word.slice(4);
            }
            return users[photo.data.uid].getRealName().indexOf(word) !== -1;
        });
    })
        .filter((photo) => photo.isInDateRangeOfCreatedAt(searchCreatedDate));
};
export const photoFilters = [
    {
        name: "全ての写真",
        key: "allphotos",
    },
    {
        name: "最近の投稿",
        key: "latestphotos",
        photoStatus: Photo.Status.before_validation,
    },
    {
        name: "バス表示中",
        key: "onthelist",
        photoStatus: Photo.Status.published,
    },
    {
        name: "バス非表示中",
        key: "offthelist",
        photoStatus: Photo.Status.notPublished,
    },
    {
        name: "ゴミ箱",
        key: "trash",
        photoStatus: Photo.Status.deleted,
    },
];
