import { defineComponent, ref } from "vue";
import { db } from "@/utils/firebase";
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import { EyeIcon } from "@heroicons/vue/24/solid";
import { EyeSlashIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import LinkButton from "@/components/LinkButton.vue";
import { usePhotoList } from "@/utils/PhotoUtil";
import { removeSymbols, useUserData } from "@/utils/utils";
export default defineComponent({
    components: {
        LinkButton,
        EyeIcon,
        EyeSlashIcon,
        FunnelIcon,
    },
    props: {
        groupId: {
            type: String,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { users } = usePhotoList(props.group.id);
        const visibleUid = ref("");
        const updateVisibleUid = (uid) => {
            if (visibleUid.value !== uid) {
                visibleUid.value = uid;
            }
            else {
                visibleUid.value = "";
            }
        };
        const { user } = useUserData();
        const recordLog = (uid) => {
            const newLogRef = doc(collection(db, "logs"));
            const log = {
                groupId: props.group.id,
                logId: newLogRef.id,
                referredData: "user.phone",
                targetUid: uid,
                refereeUid: user.value.uid,
                createdAt: serverTimestamp(),
            };
            setDoc(newLogRef, log);
        };
        return {
            users,
            visibleUid,
            updateVisibleUid,
            recordLog,
            removeSymbols,
        };
    },
});
