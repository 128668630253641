export default class FirebaseModel {
    model;
    data;
    id;
    constructor(_model) {
        this.data = _model.data();
        this.model = _model;
        this.id = _model.id;
    }
}
