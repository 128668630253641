import { defineComponent, ref, computed } from "vue";
import { db } from "@/utils/firebase";
import { doc, setDoc, serverTimestamp, writeBatch } from "firebase/firestore";
import md5 from "crypto-js/md5";
import LinkButton from "@/components/LinkButton.vue";
import { removeSymbols } from "@/utils/utils";
export default defineComponent({
    components: {
        LinkButton,
    },
    props: {
        group: {
            type: Object,
            required: true,
        },
        tags: {
            type: (Array),
            required: true,
        },
    },
    setup(props) {
        const newTagName = ref("");
        const trimedNewTagName = computed(() => newTagName.value.trim());
        const visibleTags = computed(() => props.tags.filter((tag) => !tag.deleted()));
        const tagNames = computed(() => props.tags.map((tag) => tag.data.name));
        const isSameNameTag = computed(() => tagNames.value.includes(trimedNewTagName.value));
        const disabledToAddNewTag = computed(() => isSameNameTag.value || trimedNewTagName.value === "");
        const path = `/groups/${removeSymbols(props.group.id)}/tags`;
        const addTag = () => {
            if (disabledToAddNewTag.value)
                return;
            const tagId = String(md5(trimedNewTagName.value)); // to md5 and hex
            const data = {
                groupId: props.group.id,
                tagId,
                name: trimedNewTagName.value,
                deleted: false,
                hidden: false,
                createdAt: serverTimestamp(),
            };
            const newDoc = doc(db, path + "/" + removeSymbols(tagId));
            setDoc(newDoc, data);
            newTagName.value = "";
        };
        const updatingTags = ref({});
        const addHiddenTag = (id, e) => {
            if (!updatingTags.value[id]) {
                updatingTags.value[id] = {
                    hidden: e.target.checked,
                };
            }
            else {
                updatingTags.value[id].hidden = e.target.checked;
            }
        };
        const addDeletedTag = (id, e) => {
            if (!updatingTags.value[id]) {
                updatingTags.value[id] = {
                    deleted: e.target.checked,
                };
            }
            else {
                updatingTags.value[id].deleted = e.target.checked;
            }
        };
        const disabledForUpdateButton = ref(false);
        const updateTagStatus = async () => {
            if (Object.keys(updatingTags.value).length === 0)
                return;
            disabledForUpdateButton.value = true;
            const batch = writeBatch(db);
            Object.keys(updatingTags.value).map((tagId) => {
                batch.update(doc(db, path + "/" + removeSymbols(tagId)), updatingTags.value[tagId]);
            });
            await batch
                .commit()
                .then(() => {
                updatingTags.value = {};
            })
                .catch((e) => {
                // todo: batch limit 500
                console.log(e);
            })
                .finally(() => {
                disabledForUpdateButton.value = false;
            });
        };
        return {
            newTagName,
            isSameNameTag,
            disabledToAddNewTag,
            addTag,
            visibleTags,
            updatingTags,
            addHiddenTag,
            addDeletedTag,
            updateTagStatus,
            disabledForUpdateButton,
        };
    },
});
