import { createStore } from "vuex";
export default createStore({
    state: {
        user: undefined,
        group: undefined,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setGroup(state, group) {
            // todo: 未使用メッセージが出ている。
            state.group = group;
        },
    },
    getters: {
        isSignedIn: (state) => {
            return state.user !== null && state.user !== undefined;
        },
        uid: (state) => {
            return state.user !== null && state.user !== undefined
                ? state.user.uid
                : null;
        },
        user: (state) => {
            return state.user !== null && state.user !== undefined
                ? state.user
                : null;
        },
        group: (state) => {
            return state.group !== null && state.group !== undefined
                ? state.group
                : null;
        },
    },
    actions: {},
    modules: {},
});
